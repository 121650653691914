import { JSEncrypt } from 'jsencrypt';

/* rsa加密*/
export function rsaEncrypt(data) {
  // 新建JSEncrypt对象
  let encryptor = new JSEncrypt();
  // 设置公钥
  encryptor.setPublicKey(process.env.VUE_APP_SERVICE_PUBLIC_KEY);
  // 加密数据
  return encryptor.encrypt(data);
}

/* rsa解密*/
export function rsaDecrypt(data) {
  // 新建JSEncrypt对象
  let decryptor = new JSEncrypt();
  decryptor.setPrivateKey(process.env.VUE_APP_CLIENT_PRIVATE_KEY); // 设置私钥
  // 加密数据
  return  decryptor.decrypt(data);
}

